import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Provider } from 'react-redux';
import createStore from 'src/redux/createStore';
import Header from '../Header';
import './App.scss';
import ModalContainer from '@elsa-esa/lib/components/Modals/components/ModalContainer';
import { NotificationsContainer } from '@elsa-esa/lib/components/Notifications';

const Home = React.lazy(() => import('src/components/Home'));
const Dashboard = React.lazy(() => import('src/components/Dashboard'));
const NotFound = React.lazy(() => import('src/components/NotFound'));

const App: React.FC = () => {
  const store = React.useMemo(() => createStore(), []);

  return (
    <Provider store={store}>
      <BrowserRouter>
        <Header/>
        <React.Suspense fallback={<div/>}>
          <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path="/:templateId" element={<Dashboard/>}/>
            <Route element={<NotFound/>}/>
          </Routes>
        </React.Suspense>
        <ModalContainer/>
        <NotificationsContainer/>
      </BrowserRouter>
    </Provider>
  );
};

export default React.memo(App);