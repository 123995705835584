import * as API from 'src/helpers/fetch';
import * as c from '../constants';
import { ApiFile, ApiTemplate, AppThunkAction } from 'src/types';

export const updateFilesList = (): AppThunkAction => (dispatch) => {
  return API.getFilesList().then((files) => dispatch({
    type: c.UPDATE_FILES_LIST,
    files: files.map(f => f.id),
    filesDict: files.reduce((dict, f) => {
      dict[f.id] = f;
      return dict;
    }, {} as { [fileId: string]: ApiFile }),
  }));
};

export const replaceTemplate = (template: ApiTemplate) => ({
  type: c.UPDATE_TEMPLATE,
  template,
});

export const updateTemplatesList = (): AppThunkAction => (dispatch) => {
  return API.getTemplatesList().then((templates) => dispatch({
    type: c.UPDATE_TEMPLATES_LIST,
    templates: templates.map(t => t.id),
    templatesDict: templates.reduce((dict, t) => {
      dict[t.id] = t;
      return dict;
    }, {} as { [templateId: string]: ApiTemplate }),
  }));
};

export const createTemplate = (template: Partial<ApiTemplate>): AppThunkAction => (dispatch) => {
  return API.createTemplate(template).then(template => dispatch(updateTemplatesList()));
};
export const updateTemplate = (templateId: number, template: Partial<ApiTemplate>): AppThunkAction => (dispatch) => {
  return API.updateTemplate(templateId, template).then(template => dispatch({
    type: c.UPDATE_TEMPLATE,
    template,
  }));
};

export const updateFile = (fileId: number, file: Partial<ApiFile>): AppThunkAction => (dispatch) => {
  return API.updateFile(fileId, file).then(file => dispatch({
    type: c.UPDATE_FILE,
    file,
  }));
};

export const selectTemplate = (templateId: string) => ({
  type: c.SELECT_TEMPLATE,
  templateId,
});

export const addFile = (file: ApiFile): AppThunkAction => (dispatch, getState) => {
  const { files, filesDict } = getState();
  dispatch({
    type: c.UPDATE_FILES_LIST,
    files: [file.id, ...files],
    filesDict: {
      ...filesDict,
      [file.id]: file,
    },
  });
};
