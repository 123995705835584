import * as React from 'react';
import { useSelector } from 'react-redux';
import styles from './TemplateName.scss';
import { ReduxState } from 'src/redux/emptyState';

type Props = {
  templateId: number,
};

const TemplateName: React.FC<Props> = (props) => {
  const { templateId } = props;
  const template = useSelector((state: ReduxState) => state.templatesDict[templateId]);

  if (!template) return null;
  return (
    <div className={styles.templateName}>
        <i>{template.code}</i> / {template.name}
    </div>
  );
};

export default React.memo(TemplateName);