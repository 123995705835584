// import axios, { AxiosRequestConfig }             from 'axios';
import { ApiFile, ApiTemplate, ApiTemplateFile } from 'src/types';
import fetch, { setApiUrl } from '@elsa-esa/lib/helpers/fetch';

setApiUrl('');

export type ApiProgressEvent = {
  loaded: number,
  total: number
}

// const instance = axios.create({
//   baseURL: `/api/`,
// });
//
// const fetch = <Response>(params: AxiosRequestConfig) => instance(params).then(response => response.data as Response);

export const getFilesList = () => fetch<ApiFile[]>('GET', 'files');

export const uploadFile = (file: File, onUploadProgress: (e: ApiProgressEvent) => any) => {
  const data = new FormData();
  data.append('file', file);

  return fetch<ApiFile>('POST', 'files/file', { file }, { onUploadProgress, requestType: 'form' });
};

export const uploadLink = (link: string) => fetch<ApiFile>('POST', 'files/link', { link });
export const updateFile = (fileId: number, file: Partial<ApiFile>) => fetch<ApiFile>('PUT', `files/${fileId}`, file);
export const removeFile = (file: ApiFile) => fetch('DELETE', `files/${file.id}`);
export const getFileContent = (file: ApiFile) => fetch<string[][] | Record<string, string>[]>('GET', `files/${file.id}/content`);

export const getTemplatesList = () => fetch<ApiTemplate[]>('GET', 'templates');
export const createTemplate = (template: Partial<ApiTemplate>) => fetch<ApiTemplate>('POST', 'templates', template);
export const importTemplate = (file: File) => {
  const data = new FormData();
  data.append('file', file);

  return fetch<ApiFile>('POST', 'templates/import', { file }, { requestType: 'form' });
};
export const updateTemplate = (templateId: number, template: Partial<ApiTemplate>) => fetch<ApiTemplate>('PUT', `templates/${templateId}`, template);
export const removeTemplate = (template: ApiTemplate) => fetch('DELETE', `templates/${template.id}`);
export const getTemplateCSV = (code: string) => fetch<string>('GET', `templates/${code}`);
export const getTemplateCSVFromFiles = (code: string, file?: File[]) => fetch<string>('POST', `templates/${code}`, { file }, { requestType: 'form' });

export const createTemplateFile = (templateCode: string, fileId: number) => fetch<ApiTemplate>('POST', `templates/${templateCode}/files/${fileId}`);
export const updateTemplateFile = (templateFileId: number, templateFile: Partial<ApiTemplateFile>) => fetch<ApiTemplate>('PUT', `template-files/${templateFileId}`, templateFile);
export const removeTemplateFile = (templateFileId: number) => fetch<ApiTemplate>('DELETE', `template-files/${templateFileId}`);
