import * as React from 'react';
import Papa from 'papaparse';
import { getTemplateCSV, getTemplateCSVFromFiles } from 'src/helpers/fetch';
import { downloadFile } from '@elsa-esa/lib/helpers/downloadFile';
import style from './TestTemplatePopup.scss';
import { generatePreviewTable } from 'src/helpers/generatePreviewTable';


type Props = {
  templateCode: string
};

const TestTemplatePopup: React.FunctionComponent<Props> = (props) => {
  const { templateCode } = props;
  const fileRef = React.useRef<HTMLInputElement>();

  const sendRequest = () => {
    const { files } = fileRef.current;
    if (!files.length) return getTemplateCSV(templateCode).catch((e) => {
      alert(e.message);
      throw e;
    });
    return getTemplateCSVFromFiles(templateCode, Array.from(files)).catch((e) => {
      alert(e.message);
      throw e;
    });
  };

  const onTest = () => sendRequest().then(
    (response) => {
      const { data } = Papa.parse<string[]>(response);
      const w = window.open('', '_blank');
      w.document.body.innerHTML = generatePreviewTable(data);
    },
    (e) => {},
  );

  const onDownload = () => sendRequest().then(
    (response) => {
      const blob = new Blob([response], { type: 'text/csv' });
      const blobUrl = window.URL.createObjectURL(blob);
      downloadFile(templateCode + '.csv', blobUrl);
    },
    (e) => {},
  );

  return (
    <div>
      <div className="my-4">
        <input ref={fileRef} type="file" multiple/>
        <p className="mt-2"><i>Если не выбрать файл, в обработку попадет файл из шаблона</i></p>
      </div>

      <button onClick={onTest} className="btn btn-primary">Проверить</button>
      <button onClick={onDownload} className="btn btn-success ml-2">Скачать</button>
    </div>
  );
};

export default React.memo(TestTemplatePopup);